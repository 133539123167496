<template>
  <v-menu
    left
    v-model="menu"
    :close-on-content-click="closeOnContentClick"
    ref="menu-notification"
    min-width="350px"
    max-width="350px"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="notificationUnread && (notificationUnread > 999 ? '999+' : notificationUnread)"
        :value="notificationUnread"
        color="red"
        class="mr-3"
        overlap
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="grey--text text--lighten-1"
        >
          mdi-bell
        </v-icon>
      </v-badge>
    </template>
    <v-list
      class="overflow-auto"
      :class="notificationLoading || !notifications.length ? 'd-flex align-center' : null"
      min-height="150"
      max-height="350">
      <v-list-item v-if="notificationLoading" class="justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-list-item>
      <v-list-item v-else-if="!notifications.length && !isErrorGetNotifications" class="justify-center">
        <p class="text-center">Tidak ada pesan</p>
      </v-list-item>
      <section v-else>
        <v-list-item v-if="isErrorGetNotifications" class="justify-center d-flex flex-column pt-8">
          <h3>{{$_strings.common.ERROR_MESSAGE}}</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="fetchNotificationList(notificationPage, 'notificationLoading')"
                x-large
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>
                  mdi-reload
                </v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-else
          v-for="(notification, index) in notifications"
          :key="index"
        >
          <v-card
            @click="notificationClicked(notification)"
            hover
            light
            class="mb-2 w-100"
            :class="!notification.notificationData.isRead ? 'grey lighten-3' : ''"
          >
            <v-card-title>
              <span class="caption text-subtitle-2 font-weight-black">
                {{notification.notificationData.title}}
              </span>
            </v-card-title>
            <v-card-subtitle>
              {{perseRelativeTime(notification.date)}}
            </v-card-subtitle>
            <v-card-text class="caption">
              {{notification.notificationData.body}}
            </v-card-text>
          </v-card>
        </v-list-item>
        <v-list-item-action
          v-if="(notifications.length < totalNotifications)
            && !notificationLoadingLoadMore
            && !isErrorGetNotifications
          "
          class="d-flex justify-end mr-4 caption"
        >
          <v-btn
            text
            class="caption"
            color="primary"
            @click="fetchNotificationList(notificationPage, 'notificationLoadingLoadMore')"
          >
            Muat lebih banyak..
          </v-btn>
        </v-list-item-action>
        <v-list-item v-if="notificationLoadingLoadMore" class="justify-center">
          <v-progress-circular
            :size="20"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-list-item>
        <v-btn style="margin: 0 16px; width: calc(100% - 32px);" @click="readAllNotification()">
          Baca Semua Notifikasi
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
import { isSupported } from 'firebase/messaging';
import { perseRelativeTime } from '../../helper/commonHelpers';

export default {
  data() {
    return {
      menu: false,
      notifications: [],
      notificationUnread: 0,
      notificationPage: 0,
      totalNotifications: 0,
      notificationLoading: false,
      notificationLoadingLoadMore: false,
      isErrorGetNotifications: false,
      closeOnContentClick: false,
    };
  },
  watch: {
    menu(newVal) {
      if (!newVal) {
        return;
      }
      this.notifications = [];
      this.notificationPage = 0;
      this.totalNotifications = 0;
      this.fetchNotificationList(0, 'notificationLoading');
    },
  },
  mounted() {
    this.setUpMessageNotification();
    this.getNotificationUnread();
  },
  beforeDestroy() {
    this.removeNotification();
  },
  methods: {
    async fetchNotificationList(page = 0, loading = 'notificationLoading') {
      const { companyUserId } = this.$store.state.user.myUserInfo;
      const filters = {
        userId: companyUserId,
        page,
        size: 10,
      };
      try {
        this.closeOnContentClick = false;
        this[loading] = true;
        this.isErrorGetNotifications = false;
        const resNotifications = await this.$_services.notifications.fetchNotificationList(filters);
        this.notifications = [...this.notifications, ...resNotifications.data.contents];
        this.notificationPage = resNotifications.data.page;
        this.totalNotifications = resNotifications.data.totalData;
        this.notificationUnread = resNotifications.data.unRead;
      } catch {
        if (loading === 'notificationLoading') {
          this.isErrorGetNotifications = true;
        }
      } finally {
        this[loading] = false;
      }
    },
    getNotificationUnread() {
      const { companyUserId } = this.$store.state.user.myUserInfo;
      const filters = {
        userId: companyUserId,
        page: 0,
        size: 10,
      };
      this.$_services.notifications.fetchNotificationList(filters).then((res) => {
        this.notificationUnread = res.data.unRead;
      });
    },
    messageHandler(event) {
      const { data: { data } } = event;
      console.log('Received a message from service worker: ', data);
      this.$emit('fetchValueMenu');
      this.notificationUnread += 1;
    },
    async setUpMessageNotification() {
      const _isSupported = await isSupported();
      if (_isSupported) {
        navigator.serviceWorker.addEventListener('message', this.messageHandler);
      }
    },
    async removeNotification() {
      const _isSupported = await isSupported();
      if (_isSupported) {
        navigator.serviceWorker.removeEventListener('message', this.messageHandler);
      }
    },
    perseRelativeTime,
    async readNotification(notifId) {
      await this.$_services.notifications.setNotificationRead(notifId);
      if (this.notificationUnread > 0) {
        this.notificationUnread -= 1;
      }
    },
    async notificationClicked(item) {
      if (this.$route.path === item.notificationData.redirectUrl) return;
      this.closeOnContentClick = true;
      if (item.notificationData.isRead) {
        this.$router.push(item.notificationData.redirectUrl);
        return;
      }
      try {
        this.$root.$loading.show();
        await this.readNotification(item.id);
        this.$router.push(item.notificationData.redirectUrl);
      } finally {
        this.$root.$loading.hide();
      }
    },
    readAllNotification() {
      const { companyUserId } = this.$store.state.user.myUserInfo;
      try {
        this.$_services.notifications.readAllNotifications(companyUserId);
      } catch {
        this.isErrorGetNotifications = true;
      } finally {
        this.notifications = [];
        this.fetchNotificationList();
      }
    },
  },
};
</script>
